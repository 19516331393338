











































































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { ImageSourceSet } from 'client-website-ts-library/types';
import InquiryForm from '@/components/InquiryForm.vue';
import GoogleReviews from '@/components/GoogleReviews.vue';

@Component({
  components: {
    InquiryForm,
    GoogleReviews,
  },
})
export default class WhyChooseUsSales extends Mixins(View) {
  public image: ImageSourceSet = { source: '/assets/images/photos/why-choose-us-sales', formats: ['webp', 'jpg'], fallback: 'jpg' };
}
